import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import { useTranslation } from 'next-i18next';
import Hero from '@organisms/Hero/Hero';
import PageIntro from '@organisms/PageIntro/PageIntro';

const Custom404Page = () => {
  const { t } = useTranslation('error');

  return (
    <>
      <Hero image="/img/hero-fallback.jpg" />
      <PageIntro
        title={t('title', {
          statusCode: 404,
        })}
        intro={t('text')}
      />
    </>
  );
};

export const getStaticProps = async ({ locale }) => ({
  props: {
    ...(await serverSideTranslations(locale, ['common', 'error'])),
  },
});

export default Custom404Page;
